import styles from '../css/app.pcss';

import 'promise-polyfill/src/polyfill';
import Flickity from 'flickity';
import mixitup from 'mixitup';
import lozad from 'lozad';
import Accordion from "accordion/src/accordion.mjs";
import store from './store'; // Imports Vue also, so not sure if we need to import it again dynamically

// App main
const main = async () => {
    // Async load the vue module
    const Vue = await import(/* webpackChunkName: "vue" */ 'vue');
    const VueCookies = await import( /* webpackChunkName: "vue-cookies" */ 'vue-cookies');
    const axios = await import( /* webpackChunkName: "axios" */ 'axios');
    const VueAxios = await import( /* webpackChunkName: "vue-axios" */ 'vue-axios');
    const VeeValidate = await import( /* webpackChunkName: "vee-validate" */ 'vee-validate');
    
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
    
    Vue.default.use(VueCookies);
    Vue.default.use(VueAxios, axios);
    Vue.default.use(VeeValidate, {
        classes: true,
        events: 'change|blur'
    });
    
    new Vue.default({
        el: "#site-header",
        
        data: {
            navIsOpen: false,
            searchActive: false,
            placeholder: '',
            lastScrollTop: 0,
            mastheadHeight: 0,
            hideMasthead: false
        },
        
        mounted() {
            this.mastheadHeight = this.$refs.masthead.offsetHeight;
            
            this.onWindowScroll();
        },
        
        created() {
            window.addEventListener('scroll', this.onWindowScroll);
        },
        
        beforeDestroy() {
            window.removeEventListener('scroll', this.onWindowScroll);
        },
        
        
        methods: {
            onWindowScroll() {
                if (!this.navIsOpen) {
                    this.stickyNav();
                }
            },
            
            openNav() {
                return this.navIsOpen = !this.navIsOpen;
            },
            
            searchFocus() {
                this.searchActive = true;
            },
            
            searchBlur() {
                this.searchActive = false;
            },
            
            stickyNav() {
                let scrollTop = window.pageYOffset;
                this.hideMasthead = (scrollTop > this.lastScrollTop) && (scrollTop > this.mastheadHeight);
                
                this.lastScrollTop = scrollTop;
            }
        }
    });
    
    new Vue.default({
        el: "#pop-up-help",
        store,
        
        methods: {
            togglePopUp() {
                store.commit('toggleHelpPopUp');
            }
        }
    });
    
    new Vue.default({
        el: "#news",
        delimiters: ['${', '}'],
        
        data: {
            page: 1,
            elementsToLoad: 6, // First 3 set
            articlesLoadedCount: 0,
            totalCount: 0,
            loadingEntries: false,
            allLoaded: false
        },
        
        mounted() {
            if (this.$refs.newsEntries) {
                this.totalCount = this.$refs.newsEntries.dataset.count;
            }
            
            this.articlesLoadedCount = this.elementsToLoad;
        },
        
        methods: {
            loadMoreArticles() {
                let vm = this;
                
                // Load more btn was clicked so we're loading entries and we're hiding the button and showing the loader
                this.loadingEntries = true;
                
                axios.get("ajax/news/p" + this.page)
                    .then(function (response) {
                        // Get element to append articles to
                        let parent = vm.$refs.newsEntries;
                        
                        // Append loaded articles at the end of the element
                        parent.insertAdjacentHTML('beforeend', response.data);
                        
                        
                        // Set next pagination page
                        vm.page++;
                        
                        // Update our article oaded count
                        vm.articlesLoadedCount += vm.elementsToLoad;
                        
                        // Show the load more articles button again
                        vm.loadingEntries = false;
                        
                        // Fade in new elements (set timeout because else it doesn't work properly)
                        setTimeout(function () {
                            for (const el of Array.from(document.getElementsByClassName('newly-loaded'))) {
                                el.classList.remove("opacity-0");
                            }
                        }, 100);
                        
                        
                        // Check if all articles are loaded to hide the load more button
                        if (vm.articlesLoadedCount >= vm.totalCount) {
                            vm.allLoaded = true;
                        }
                    })
                    .catch(function (error) {
                        //console.log("error fetching articles", error);
                    });
            }
        }
    });
    
    new Vue.default({
        el: "#contact-form",
        delimiters: ['${', '}'],
        
        data: {
            formStatus: '',
            sending: false,
            initialTrafficSource: ''
        },
        
        mounted() {
            this.initialTrafficSource = VueCookies.get('initialTrafficSource');
        },
        
        methods: {
            sendForm(siteUrl) {
                let vm = this;
                
                this.$validator.validate().then(valid => {
                    if (valid) {
                        let contactForm = document.getElementById('contactform');
                        let data = new FormData(contactForm);
                        
                        this.sending = true;
                        
                        axios.post('.', data)
                            .then(function (response) {
                                vm.formStatus = 'success';
                                vm.sending = false;
                                // Redirect to thank you page
                                window.location.href = siteUrl + "thanks";
                            })
                            .catch(function (error) {
                                vm.formStatus = 'error';
                                vm.sending = false;
                            });
                    } else {
                        // Validation errors are handled inside the form
                    }
                });
            }
        }
    });
    
    if (document.getElementById('content-block--slider') !== null) {
        new Vue.default({
            el: "#content-block--slider",
            
            mounted(){
                let slider = document.getElementsByClassName('main-carousel')[0];
                // Show
                slider.classList.remove('hidden');
                // Trigger redraw for transition
                slider.offsetHeight;
                
                let flkty = new Flickity('.main-carousel', {
                    // options
                    cellAlign: 'left',
                    contain: true,
                    wrapAround: true,
                    autoPlay: 6000,
                    adaptiveHeight: true,
                    initialIndex: 0,
                    pageDots: false,
                    prevNextButtons: false
                });
    
                this.$refs.sliderPrev.addEventListener('click', () => {
                    flkty.previous();
                });
    
                this.$refs.sliderNext.addEventListener('click', () => {
                    flkty.next();
                });
            }
        });
    }
    
    if (document.getElementsByClassName('content-block--video-carousel') !== null) {
        new Vue.default({
            el: document.getElementsByClassName('content-block--video-carousel')[0],
            
            mounted(){
                let slider = document.getElementById('video-carousel');
                let flkty = new Flickity(slider, {
                    cellAlign: 'left',
                    wrapAround: true,
                    pageDots: false,
                    prevNextButtons: false
                });
                
                this.$refs.sliderPrev.addEventListener('click', () => {
                    flkty.previous();
                });
                
                this.$refs.sliderNext.addEventListener('click', () => {
                    flkty.next();
                });
            }
        });
    }
};

// Execute async function
main().then((value) => {
    // MixItUp
    if (document.querySelectorAll(".mix").length) {
        mixitup('.article-wrapper');
    }
    
    // Accordion (eg. FAQ)
    for (const el of document.querySelectorAll(".accordion")) {
        new Accordion(el, {
            modal: true,
            useBorders: true,
            onToggle: function (fold, isOpen) {
                let icon = fold.heading.querySelector('.icon-chevron');
                if (isOpen) {
                    icon.classList.add('open');
                } else {
                    icon.classList.remove('open');
                }
            }
        });
    }
});
