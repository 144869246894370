import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        popUpOpen: true
    },
    
    mutations: {
        toggleHelpPopUp(state) {
            state.popUpOpen = !state.popUpOpen;
        }
    },
    
    plugins: [createPersistedState({
        paths: ['popUpOpen']
    })]
});